import React from "react"
import styled from "styled-components"
import Upwaltham from "../images/upwaltham.jpg"
import LeafBorder from "../images/hand-painted-leaf-border-cut.png"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Picture = styled.div`
  background-image: url("${Upwaltham}");
  width: 100%;
  height: 400px;
  background-position: 40%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 300px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const Subheader = styled.h3`
  font-family: "Benton Sans";
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 42px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Tab = styled.span`
  padding: 0 15px;
`

class SaturdayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Saturday | Sara & Tim" />
        <H1>SATURDAY, OCTOBER 23RD</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <Subheader>Wedding venue</Subheader>
        <H4>
          Upwaltham Barns - A285, Upwaltham, Petworth, United Kingdom, GU28 0LX
        </H4>
        <Subheader>Order of the day</Subheader>
        <P>
          13:00<Tab></Tab>Venue and bar open for guests
        </P>
        <P>
          14:00<Tab></Tab>Wedding ceremony
        </P>
        <P>
          14:30<Tab></Tab>Drinks reception and photos
        </P>
        <P>
          16:00<Tab></Tab>Dinner is served
        </P>
        <P>
          20:00<Tab></Tab>Cake cutting and the first dance
        </P>
        <P>
          20:30<Tab></Tab>Ceilidh dancing
        </P>
        <P>
          21:00<Tab></Tab>Late night snacks
        </P>
        <P>
          00:00<Tab></Tab>Carriages
        </P>
      </Layout>
    )
  }
}

export default SaturdayPage
